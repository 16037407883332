import { BaseElementController } from "elements/base/controller";
import type { TaskCard } from "shared/datamodel/schemas/task-card";
import type { IGanttController } from "../controller";
import { cleanDate, getRowColor } from "../utils";
import { IGanttBaseCellController } from "elements/gantt/controllers/base-cell-controller";

export default class GanttTaskCellController
  extends BaseElementController<TaskCard>
  implements IGanttBaseCellController<TaskCard>
{
  #ganttController: WeakRef<IGanttController> | undefined;

  updateElement(element: TaskCard): void {
    super.updateElement(element);
    this.notify();
  }

  updateController(ganttController: IGanttController): void {
    this.#ganttController = new WeakRef(ganttController);
    this.notify();
  }

  getLayout() {
    return this.#ganttController?.deref()?.getTaskCellLayout(this.id);
  }

  getTitle() {
    return this.element.title;
  }

  getBackgroundColor() {
    return getRowColor(this.element.color);
  }

  getGanttStartDate() {
    const cell = this.#ganttController?.deref()?.getDateColumnsLayout()[0];
    return new Date(cell?.id.split("cell-")[1] ?? 0);
  }

  getGanttEndDate() {
    const cell = this.#ganttController
      ?.deref()
      ?.getDateColumnsLayout()
      .filter((cell) => !cell.isHeader)
      .at(-1);

    return new Date(cell?.id.split("cell-")[1] ?? 0);
  }

  getStartDate() {
    return cleanDate(this.element.fromDate || new Date());
  }

  getEndDate() {
    return cleanDate(this.element.dueDate || this.element.toDate || new Date());
  }

  changeTitle(newTitle: string) {
    this.#ganttController?.deref()?.changeTaskTitle(this.id, newTitle);
  }

  isSelected() {
    return this.#ganttController?.deref()?.context.selectedElementIds.includes(this.id) ?? false;
  }

  isLoading(): boolean {
    return false;
  }
}
