import { BoardContext } from "elements/index";
import type { Reflect } from "@workcanvas/reflect/client";
import type { M } from "shared/datamodel/mutators";

export const createMockReflect = ({
  subscribeFunc,
}: {
  subscribeFunc: (query: () => any, callback: (data: any) => any) => void;
}): Reflect<M> => {
  return {
    subscribe: subscribeFunc as Reflect<M>["subscribe"],
    unsubscribe: () => {},
    get: () => {},
    set: () => {},
    delete: () => {},
    apply: () => {},
    batch: () => {},
  } as any;
};

export const createEmptyContext = ({ reflectOverrides }: { reflectOverrides?: Reflect<M> }): BoardContext => {
  const context: BoardContext = {
    boardId: 0,
    documentId: "",
    isReadOnly: true,
    reflect: reflectOverrides as Reflect<M>,
    selectedElementIds: [],
    setSelectedIds: () => {},
    setMovingElements: () => {},
    hiddenElementIds: [],
    editingElementId: null,
    undoRedoStack: {
      addAction: () => {},
      undo: () => {},
      redo: () => {},
      startSequence: () => {},
      endSequence: () => {},
    } as any,
  };

  return context;
};
