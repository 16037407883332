import { customAlphabet } from "nanoid";
import Konva from "konva";
import { GanttSplitCell } from "elements/gantt/layout-builder";
import { ReadTransaction } from "@workcanvas/reflect";
import { SyncService } from "frontend/services/syncService";
import consts from "shared/consts";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { canvasElementPrefix } from "shared/util/utils";
import { IntegrationItem, TaskCard } from "shared/datamodel/schemas";
import { GanttAnalyticsEvents, TaskColor, TasksColors } from "elements/gantt/constants";
import tracking from "frontend/tracking";

export function createCellId() {
  const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const nanoid = customAlphabet(alphabet, 10);
  return nanoid();
}

export function isTargetSplitCell(target: Konva.Node) {
  return findNodeStartsWithId(target, "cell-");
}

export function isTargetAnchor(target: Konva.Node) {
  return findNodeStartsWithId(target, "anchor-");
}

export function isTargetGridCell(target: Konva.Node) {
  return findNodeStartsWithId(target, "grid-");
}

export function isTargetTaskCell(target: Konva.Node) {
  return findNodeStartsWithId(target, "task-");
}

export function isTargetConnector(target: Konva.Node) {
  return findNodeStartsWithId(target, "gantt-connector-");
}

function findNodeStartsWithId(node: Konva.Node, id: string) {
  let parent = node;
  while (parent) {
    if (parent.attrs && parent.attrs.id === "gantt-parent-node") {
      return false;
    }
    if (parent.attrs && parent.attrs.id && parent.attrs.id.startsWith(id)) {
      return true;
    }
    parent = parent.getParent();
  }
  return false;
}

export enum CornerRadius {
  None = 0,
  TopLeft = 1 << 0,
  TopRight = 1 << 1,
  BottomRight = 1 << 2,
  BottomLeft = 1 << 3,
}

export function getCornerRadius(cornerRadius: CornerRadius, value: number): number[] {
  return [
    cornerRadius & CornerRadius.TopLeft ? value : 0,
    cornerRadius & CornerRadius.TopRight ? value : 0,
    cornerRadius & CornerRadius.BottomRight ? value : 0,
    cornerRadius & CornerRadius.BottomLeft ? value : 0,
  ];
}

export function getRelatedRows(
  data: (GanttSplitCell | { rowId: string; parentRowId?: string })[],
  rowId: string
): any[] {
  const newData = [...data];
  newData.reverse();

  const relevantIds = new Set();

  newData.forEach((item) => {
    if (item.rowId === rowId || relevantIds.has(item.parentRowId) || relevantIds.has(item.rowId)) {
      relevantIds.add(item.rowId);
      if (item.parentRowId) {
        relevantIds.add(item.parentRowId);
      }
    }
  });

  relevantIds.forEach((id) => {
    newData.forEach((item) => {
      if (item.rowId === id || item.parentRowId === id) {
        relevantIds.add(item.rowId);
      }
    });
  });

  return newData.filter((item) => relevantIds.has(item.rowId));
}

export function getRowColor(color: string): TaskColor {
  if (!color || !TasksColors.includes(color)) {
    return TasksColors[0] as TaskColor;
  }
  return color as TaskColor;
}

export function countGanttTaskNodesInReflect(syncService: SyncService<RW>, containerIds: string[]) {
  return syncService.getReplicache()!.query(async function (tx: ReadTransaction) {
    const allElements = (await tx.scan({ prefix: canvasElementPrefix }).toArray()).filter((element) => {
      const taskCard = element as TaskCard;
      return taskCard?.containerId?.includes(consts.CANVAS_ELEMENTS.GANTT) && !taskCard.hidden;
    });

    if (containerIds.length > 0) {
      return allElements.filter((element) => {
        const taskCard = element as TaskCard;
        return taskCard.containerId && containerIds.some((id) => id === taskCard.containerId);
      }).length;
    }

    return allElements.length;
  });
}
type PayloadForEvent<T extends GanttAnalyticsEvents["event"]> = Extract<GanttAnalyticsEvents, { event: T }>["payload"];

export const trackGanttEvent = <E extends GanttAnalyticsEvents["event"]>(
  event: E,
  payload?: PayloadForEvent<E>
): void => {
  tracking.trackAnalyticsEvent(event, payload);
};

export const cleanDate = (d: Date | number) => {
  return new Date(new Date(d).toDateString());
};

export const extractStartAndEndDate = (element: TaskCard | IntegrationItem) => {
  if (element.type === "taskCard") {
    return {
      fromDate: element.fromDate as number,
      toDate: (element.toDate || element.dueDate) as number,
    };
  }
  if (element.type === "integrationItem") {
    if (!element.fieldValues?.["fromDate"] && !element.fieldValues?.["toDate"]) {
      return null;
    }
    return {
      fromDate: element.fieldValues?.["fromDate"] as number,
      toDate: element.fieldValues?.["toDate"] as number,
    };
  }

  return null;
};
