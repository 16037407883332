const ganttConstant = {
  RowHeight: 90,
  HeaderHeight: 50,
  SubHeaderHeight: 30,
  SplitColumnWidth: 100,
  DateByDayColumnWidth: 120,
  DateByWeekColumnWidth: 120,
  DateByMonthColumnWidth: 120,
  LayoutBorderColor: "#CDCFD1",
  LayoutBorderWidth: 1,
  SelectedLayoutBorderWidth: 2,
  LayoutDateHeaderFill: "#F0F1F5",
  LayoutDateSubheaderFill: "#F8F9FD",
  HeaderFontSize: 18,
  HeaderSubFontSize: 14,
  HeaderBorderRadius: 5,
  SelectedCellBorderColor: "#0072FF",
  SplitCellOrderGap: 2 ** 10,
  SplitCellMinOrderGap: 2 ** 5,
  // will pad to start of week, day or month
  UsePaddingForDates: false,
  // keys are primary color, values are [secendary color, regular color]
  TasksColors: {
    "#F4A0A0": ["#FBD5D5", "#FBD5D5" + "66"],
    "#FFC971": ["#FFDEA9", "#FFDEA9" + "66"],
    "#F9ED81": ["#FFF9C3", "#FFF9C3" + "66"],
    "#87E3B7": ["#C4FFE3", "#C4FFE3" + "66"],
    "#A0E0F4": ["#C7F0FC", "#C7F0FC" + "66"],
    "#A78FE3": ["#E4DAFF", "#E4DAFF" + "66"],
  },
  taskCellPadding: 10,
  TaskTitleRowHeight: 20,
  MinimumCellsWidth: 7,
  MaxWidthUnderMinimumCells: 1000,
  DebounceIntegrationSyncTimeMs: 1500,
};

export type TaskColor = keyof typeof ganttConstant.TasksColors;
export const TasksColors = Object.keys(ganttConstant.TasksColors).reverse();

export type GanttAnalyticsEvents =
  | {
      event: "gantt_task_created";
      payload: {
        amount: number;
        from: "gantt_actions" | "db_click" | "copy_paste" | "dragged";
      };
    }
  | {
      event: "gantt_column_added";
      payload: {
        from: "gantt_actions" | "column_toolbar" | "circle_button";
      };
    }
  | {
      event: "gantt_row_added";
      payload: {
        from: "gantt_actions" | "column_toolbar" | "circle_button";
      };
    }
  | {
      event: "gantt_row_deleted";
      payload: {
        from: "column_toolbar";
      };
    }
  | {
      event: "gantt_column_deleted";
      payload: {
        from: "column_toolbar";
      };
    }
  | {
      event: "gantt_task_opend";
      payload: {
        from: "chevron_click" | "db_click";
      };
    }
  | {
      event: "gantt_task_edited";
      payload: {
        from: "gantt" | "task-popup";
        field: "title" | "date";
      };
    }
  | {
      event: "gantt_dates_changed";
      payload: {};
    }
  | {
      event: "gantt_granularity_changed";
      payload: {};
    }
  | {
      event: "gantt_connector_added";
      payload: {};
    }
  | {
      event: "gantt_connector_removed";
      payload: {};
    };

export default ganttConstant;
