import React from "react";

export const GanttIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 32 24">
      <path
        stroke="#113357"
        strokeWidth="2"
        d="M2.6 22.25V18.5h18.8v3.75zM10.6 13.5V9.75H31v3.75zM1 4.75V1h23.6v3.75z"
      ></path>
    </svg>
  );
};
