import { BaseElementController } from "elements/base/controller";
import type { IGanttController } from "../controller";
import { cleanDate, extractStartAndEndDate, getRowColor } from "../utils";
import { IntegrationItem } from "shared/datamodel/schemas";
import { updateIntegrationItem } from "frontend/api";
import { BoardContext } from "elements/index";
import { IGanttBaseCellController } from "elements/gantt/controllers/base-cell-controller";
import { differenceInSeconds } from "date-fns";
import { getIntegrationItemGanttValues } from "elements/gantt/controllers/controller-utils";

export default class GanttMondayItemCellController
  extends BaseElementController<IntegrationItem>
  implements IGanttBaseCellController<IntegrationItem>
{
  #ganttController: WeakRef<IGanttController> | undefined;

  #startDateColumnId: string | null | undefined = null;
  #endDateColumnId: string | null | undefined = null;
  #url: string | null | undefined = null;

  #isLoading: boolean = false;
  #isInitialLoading: boolean = true;
  private debounceTimeout: NodeJS.Timeout | null = null;
  #lastCheckIntegration: number = 0;

  constructor(id: string, element: IntegrationItem, context: BoardContext) {
    super(id, element, context);
  }

  async fetchData(updateUsing: "reflect" | "monday") {
    this.#isLoading = true;
    await getIntegrationItemGanttValues(
      this.context.documentId,
      this.element.integrationId,
      this.element.configuration.itemId
    ).then(async (e) => {
      this.#url = e?.url;
      await this.patchElement(this.id, (draft: IntegrationItem) => {
        draft.fieldValues ??= {};

        if (e && e.startId && e.endId && e.startId !== e.endId) {
          this.#startDateColumnId = e.startId;
          this.#endDateColumnId = e.endId;
        }

        if (updateUsing === "monday" && e && e.startId !== e.endId) {
          const startDate = new Date(e.startDate).getTime();
          const endDate = new Date(e.endDate).getTime();
          if (startDate && endDate && startDate <= endDate) {
            draft.fieldValues["fromDate"] = startDate;
            draft.fieldValues["toDate"] = endDate;
          }
        } else if (updateUsing === "reflect") {
          const dates = extractStartAndEndDate(this.element);
          if (dates) {
            if (dates.fromDate && dates.toDate && dates.fromDate <= dates.toDate) {
              draft.fieldValues["fromDate"] = dates.fromDate;
              draft.fieldValues["toDate"] = dates.toDate;
            }
          }
        }
        if (e) {
          draft.fieldValues["title"] = e.title;
        }
      });
      this.#isLoading = false;
      this.#isInitialLoading = false;
    });
  }

  updateElement(element: IntegrationItem): void {
    super.updateElement(element);
    this.notify();
  }

  updateController(ganttController: IGanttController): void {
    this.#ganttController = new WeakRef(ganttController);
    this.notify();
  }

  getLayout() {
    return this.#ganttController?.deref()?.getTaskCellLayout(this.id);
  }

  getTitle() {
    return this.element.fieldValues?.["title"] || "";
  }

  getBackgroundColor() {
    return getRowColor(this.element.fill ?? "");
  }

  getGanttStartDate() {
    const cell = this.#ganttController?.deref()?.getDateColumnsLayout()[0];
    return new Date(cell?.id.split("cell-")[1] ?? 0);
  }

  getGanttEndDate() {
    const cell = this.#ganttController
      ?.deref()
      ?.getDateColumnsLayout()
      .filter((cell) => !cell.isHeader)
      .at(-1);

    return new Date(cell?.id.split("cell-")[1] ?? 0);
  }

  getStartDate() {
    return cleanDate(this.element.fieldValues?.["fromDate"] ?? new Date().getTime());
  }

  getEndDate() {
    return cleanDate(this.element.fieldValues?.["toDate"] ?? new Date().getTime());
  }

  changeTitle(newTitle: string) {
    updateIntegrationItem(this.element.integrationId, this.element.configuration.itemId, {
      name: newTitle,
    });
  }

  isSelected() {
    return this.#ganttController?.deref()?.context.selectedElementIds.includes(this.id) ?? false;
  }

  async updateIntegration(
    updateUsing: "reflect" | "monday",
    data?:
      | {
          itemId: number;
          columnValues?: {
            id: string;
            type: string;
            value: string;
          }[];
          name: string;
        }
      | null
      | undefined
  ) {
    if (updateUsing === "monday") {
      this.updateIntegrationAction(updateUsing, data);
      return;
    }

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      this.updateIntegrationAction(updateUsing, data);
    }, 300);
  }

  async updateIntegrationAction(
    updateUsing: "reflect" | "monday",
    data?:
      | {
          itemId: number;
          columnValues?: {
            id: string;
            type: string;
            value: string;
          }[];
          name: string;
        }
      | null
      | undefined
  ) {
    const title = data?.name ?? this.element.fieldValues?.["title"];

    let eventFromDate: string | number | undefined =
      data?.columnValues && data?.columnValues?.find((column) => column.id === this.#startDateColumnId)?.value;
    if (eventFromDate) {
      eventFromDate = new Date(eventFromDate).getTime();
    }

    let eventToDate: string | number | undefined =
      data?.columnValues && data?.columnValues?.find((column) => column.id === this.#endDateColumnId)?.value;
    if (eventToDate) {
      eventToDate = new Date(eventToDate).getTime();
    }

    const fromDate = cleanDate(eventFromDate ?? this.element.fieldValues?.["fromDate"]).getTime();
    const toDate = cleanDate(eventToDate ?? this.element.fieldValues?.["toDate"]).getTime();

    if (data) {
      await this.patchElement(this.id, (draft: IntegrationItem) => {
        draft.fieldValues ??= {};
        draft.fieldValues["title"] = title;

        if (fromDate <= toDate) {
          draft.fieldValues["fromDate"] = fromDate;
          draft.fieldValues["toDate"] = toDate;
        }
      });
      return;
    }

    this.#isLoading = true;
    const payload: Record<string, string> = {};

    if (
      !this.#startDateColumnId ||
      !this.#endDateColumnId ||
      differenceInSeconds(this.#lastCheckIntegration, Date.now()) > 60
    ) {
      await this.fetchData(updateUsing);
      this.#lastCheckIntegration = Date.now();
    }

    if (fromDate && this.#startDateColumnId) {
      payload[this.#startDateColumnId] = new Date(fromDate).toLocaleDateString("en-CA");
    }

    if (toDate && this.#endDateColumnId) {
      payload[this.#endDateColumnId] = new Date(toDate).toLocaleDateString("en-CA");
    }

    if (Object.keys(payload).length === 0) {
      this.#isLoading = false;
      return;
    }

    await updateIntegrationItem(this.element.integrationId, this.element.configuration.itemId, payload);

    this.#isLoading = false;
  }

  isLoading(): boolean {
    return this.#isLoading || this.#isInitialLoading;
  }

  isInitialLoading(): boolean {
    return this.#isInitialLoading;
  }

  getLink() {
    return this.#url || "";
  }
}
