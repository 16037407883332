import { useMemo, useRef } from "react";

export default function useDebounceCallback(callback: Function, msec = 300) {
  const timeout = useRef(0);
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  return useMemo(
    () => (...args: any[]) => {
      const later = () => {
        timeout.current = 0;
        callbackRef.current && callbackRef.current(...args);
      };
      window.clearTimeout(timeout.current);
      timeout.current = window.setTimeout(later, msec);
    },
    [msec]
  );
}
