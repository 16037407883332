import { getBoardIntegrations, getIntegrationItems } from "frontend/api";

export const getMondayBoardItemMapping = async (
  documentId: string,
  integrationId: string
): Promise<{
  mapping: Record<string, string>;
  shouldApplyForAll: boolean;
} | null> => {
  const allConfigurations = await getBoardIntegrations(documentId);
  const configuration = allConfigurations.find((c: any) => c.id === integrationId);
  return configuration.configuration?.columnMappings;
};

const combineMappingWithData = (
  columnMappings: {
    mapping: Record<string, string>;
    shouldApplyForAll: boolean;
  } | null,
  item: {
    name: string;
    columnValues: {
      id: string;
      type: string;
      value: string;
    }[];
  }
) => {
  const startId = columnMappings?.mapping?.start_date;
  const endId = columnMappings?.mapping?.end_date;

  const dateColumns = item?.columnValues?.filter((column: any) => column.type === "date");
  const startDate = dateColumns?.find((column: any) => column.id === startId)?.value || "";
  const endDate = dateColumns?.find((column: any) => column.id === endId)?.value || "";
  const title = item.name;

  return {
    startDate: new Date(new Date(startDate).toDateString()).getTime(),
    endDate: new Date(new Date(endDate).toDateString()).getTime(),
    title,
    startId,
    endId,
  };
};

export const getIntegrationItemGanttValues = (documentId: string, integrationId: string, itemId: string) => {
  return new Promise<{
    startDate: number;
    endDate: number;
    title: string;
    startId: string | undefined;
    endId: string | undefined;
    url: string | undefined;
  } | null>(async (res, rej) => {
    const columnMappings = await getMondayBoardItemMapping(documentId, integrationId);
    if (!columnMappings) {
      return res(null);
    }

    getIntegrationItems(documentId, {
      [integrationId]: { itemIds: [itemId] },
    })
      .then(async (d) => {
        const item = (d[integrationId].items ?? []).find((item: any) => item.id === itemId);
        if (!item) {
          return res(null);
        }
        const data = { ...combineMappingWithData(columnMappings, item), url: item.url };

        return res(data);
      })
      .catch((e) => {
        console.error(e);
        rej(e);
      });
  });
};
