import { Rect, Text, Group, Line } from "react-konva";
import { Html } from "react-konva-utils";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import React from "react";
import Konva from "konva";
import { format, formatDistance } from "date-fns";
import FlexBox from "frontend/ui-components/konva-box";
import { useAtomValue, useSetAtom } from "jotai";
import { errorMessageAtom, isAnyKindOfExportAtom, transformerRefAtom, utilsAtom } from "state-atoms";
import IntegrationConfiguration from "../overlays/integrations/integration-configuration";
import MondayStatusPicker from "../overlays/integrations/monday-status-picker";
import MondayDatePicker from "../overlays/integrations/monday-date-picker";
import Hoverable from "frontend/ui-components/hoverable";
import { handleTabInTextArea } from "frontend/canvas-designer-new/text-element";
import { useOnClickOutside } from "usehooks-ts";
import useDoubleClick from "frontend/hooks/use-double-click";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import { convertToLocalTime } from "shared/util/date-utils";
import SomeImage from "frontend/ui-components/some-image";
import MondayTaskPreviewAssetsCarousel from "./monday-task-preview-assets-carousel";
import { TextConfig } from "konva/types/shapes/Text";
import { getSelectedStatus } from "../../utils/monday-utils";
import ClickDragDetectorGroup from "./click-drag-detector-group";
import { getPathPrefix } from "frontend/utils/getPathPrefix";

const IntegrationToggleButton = ({ on, onClick }: { on: boolean; onClick?: () => void }) => {
  return (
    <Group onClick={() => onClick && onClick()}>
      <Hoverable hoverFill="#EFEFEF" width={24} height={18} cornerRadius={2} cursor="pointer" />
      <Line
        points={on ? [6, 12, 12, 6, 18, 12] : [6, 6, 12, 12, 18, 6]}
        stroke={"#11355E"}
        strokeWidth={1}
        lineJoin={"round"}
        lineCap="round"
      />
    </Group>
  );
};

const Metrics = {
  cardWidth: 480,
  cardPadding: 25,
  rowsGap: 20,
  titleWidth: 395,
  titlePaddingX: 0,
  titlePaddingY: 0,
};

const fontFamily = "Poppins";
const fontSize = 16;
const fontColor = "#11355E";
const lineHeight = 1.3;

function cardText(text: string, props?: Konva.TextConfig) {
  return (
    <Text
      text={text}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fill={fontColor}
      lineHeight={lineHeight}
      fontVariant="400"
      {...props}
    />
  );
}

function cardValueText(text: string, props?: Konva.TextConfig) {
  return cardText(text, { fill: "#848199", ...props });
}

export default function MondayTaskElement({
  id,
  color,
  element,
  documentId,
  integrationId,
  selectedColumns = [],
  configuration,
  onChangeConfiguration,
  allColumns,
  onChangeColumnValue,
  isEditable,
}: {
  id: string;
  color?: string | null;
  element: any;
  documentId: string;
  integrationId: string;
  selectedColumns: any[];
  configuration: any;
  onChangeConfiguration: (configuration: any) => void;
  allColumns: any[];
  onChangeColumnValue: (
    columnId: string,
    value: any
  ) => Promise<{
    status: "success" | "faild" | "not-fulfilled";
    reason?: string;
  }>;
  isEditable: boolean;
}) {
  const setError = useSetAtom(errorMessageAtom);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [expandedUpdateIds, setExpandedUpdateIds] = useState<string[]>([]);
  const [showAllUpdates, setShowAllUpdates] = useState<boolean>(false);
  const [showConfiguration, setShowConfiguration] = useState<boolean>(false);
  const [openedColumnId, setOpenedColumnId] = useState<string | null>(null);
  const [hoveredElement, setHoveredElement] = useState<any>(null);
  const canvasUtilsAtom = useAtomValue(utilsAtom(documentId)); // TODO: check document id
  const transformerRef = useAtomValue(transformerRefAtom);
  const [isEditing, setIsEditing] = useState(false);
  const [textHeight, setTextHeight] = useState(16 * 1.3); // TODO: calculate from the rendered text
  const isExporting = useAtomValue(isAnyKindOfExportAtom);
  const dblClickToEditTitle = useDoubleClick(() => setIsEditing(true));
  const newSelectedColumns = (selectedColumns?.length > 0 ? selectedColumns : element.columns) ?? [];
  const statusColumn = newSelectedColumns.find((column: any) => column.type === "status");
  const status = statusColumn && getSelectedStatus(element.columnValues, statusColumn);
  const dueDateColumn = newSelectedColumns.find((column: any) => column.type === "date");
  // const assigneeColumn = newSelectedColumns.find((column: any) => column.type === "people");

  const defaultColor = "#1973FF";
  let strokeColor = color ?? defaultColor;
  if (configuration.colorBy) {
    const colorByColumn = allColumns.find((column: any) => column.id === configuration.colorBy);
    if (colorByColumn) {
      strokeColor = getSelectedStatus(element.columnValues, colorByColumn)?.color ?? defaultColor;
    }
  }

  const { previewAssetIds = [], groupId } = configuration ?? {};

  const previewAssets = element.columnValues
    ? element.columnValues.reduce((acc: any[], columnValue: any) => {
        if (columnValue.type === "file") {
          for (const file of columnValue.value) {
            if (file.isImage && previewAssetIds.includes(file.id)) {
              acc.push(file);
            }
          }
        }
        return acc;
      }, [])
    : [];

  useEffect(() => {
    if (!element || !element.group || groupId === element.group.id) {
      return;
    }
    // update the configuration with group id
    // used for template matching
    onChangeConfiguration({ ...configuration, groupId: element.group.id });
  }, [element.group?.id, groupId]);

  const dueDate =
    dueDateColumn &&
    (element.columnValues ?? []).find((columnValue: any) => columnValue.id === dueDateColumn.id)?.value;

  function expandChanged(expanded: boolean) {
    setExpanded(expanded);
    setTimeout(() => transformerRef.current?.forceUpdate());
    canvasUtilsAtom?.moveToFrontNoUndo(id);
  }

  function expandUpdateChanged(updateId: string, expanded: boolean) {
    if (expanded) {
      setExpandedUpdateIds([...expandedUpdateIds, updateId]);
    } else {
      setExpandedUpdateIds(expandedUpdateIds.filter((id) => id !== updateId));
    }
    setTimeout(() => {
      transformerRef.current?.forceUpdate();
    });
  }

  function toggleShowAllUpdates() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.INTEGRATIONS, "task_show_all_updates_clicked");
    setShowAllUpdates(!showAllUpdates);
    setTimeout(() => {
      transformerRef.current?.forceUpdate();
    });
  }

  function renderCollapsedColumnValues() {
    if (!element || !element.columnValues) {
      return <Rect width={Metrics.cardWidth} height={40} />;
    }

    const dueDate =
      dueDateColumn && element.columnValues.find((columnValue: any) => columnValue.id === dueDateColumn.id)?.value;
    const dueDateText = dueDate ? format(convertToLocalTime(new Date(dueDate)), "MMM dd") : "Unset";

    return (
      <FlexBox padding={Metrics.cardPadding} gap={16} align="center">
        {statusColumn && (
          <Group onClick={() => isEditable && setOpenedColumnId(statusColumn.id)}>
            {isEditable && <Hoverable hoverFill="#EFEFEF" cursor="pointer" />}
            <FlexBox gap={Metrics.rowsGap} paddingX={16} paddingY={6} background={{ fill: status.color }}>
              {cardText(status.text, { fill: "white" })}
            </FlexBox>
            {openedColumnId == statusColumn.id && isEditable && (
              <EditWrapper>
                <MondayStatusPicker
                  onSelect={(id) => changeColumnValue(statusColumn.id, { index: id })}
                  column={statusColumn}
                  onDismiss={() => setOpenedColumnId(null)}
                />
              </EditWrapper>
            )}
          </Group>
        )}
        {dueDateColumn && (
          <Group onClick={() => isEditable && setOpenedColumnId(dueDateColumn.id)}>
            {isEditable && <Hoverable hoverFill="#EFEFEF" cursor="pointer" />}
            <FlexBox gap={8} paddingX={12} paddingY={6} align="center">
              <SomeImage
                src={`../${getPathPrefix("/images/task-due-date-icon.svg")}`}
                size={{ width: 25, height: 25 }}
              />
              {cardText(dueDateText, { fill: "#848199", fontVariant: "300" })}
            </FlexBox>
            {openedColumnId == dueDateColumn.id && isEditable && renderEditValue(dueDateColumn)}
          </Group>
        )}
      </FlexBox>
    );
  }

  function changeColumnValue(columnId: string, value: any) {
    tracking.trackEvent(consts.TRACKING_CATEGORY.INTEGRATIONS, "change_column_value", columnId);
    onChangeColumnValue(columnId, value).then((e) => {
      if (e.status === "faild") {
        setError("You don't have permission to edit this item.");
      }
    });
    setOpenedColumnId(null);
  }

  function renderEditValue(column: any) {
    const { id: columnId, type: columnType } = column ?? {};
    if (!columnType || !columnId) {
      return null;
    }

    if (!isEditable || openedColumnId !== columnId) {
      return null;
    }

    switch (columnType) {
      case "status":
        return (
          <EditWrapper>
            <MondayStatusPicker
              onSelect={(id) => changeColumnValue(columnId, { index: id })}
              column={column}
              onDismiss={() => setOpenedColumnId(null)}
            />
          </EditWrapper>
        );
      case "date":
        return (
          <EditWrapper>
            <MondayDatePicker
              onSelect={(date) => {
                changeColumnValue(columnId, { date });
                setOpenedColumnId(null);
              }}
              onDismiss={() => setOpenedColumnId(null)}
              selected={dueDate ? new Date(dueDate) : undefined}
            />
          </EditWrapper>
        );
      default:
        return null;
    }
  }

  function columnValueContent(columnValue: { type: string; value?: any | null; id: string }) {
    let { type, value, id } = columnValue;
    const column = allColumns.find((c) => c.id === id);
    if (!column) {
      return null;
    }

    switch (type) {
      case "status":
        return (
          <Group
            onClick={() => {
              tracking.trackEvent(consts.TRACKING_CATEGORY.INTEGRATIONS, "column_value_clicked", type);
              setOpenedColumnId(id);
            }}
          >
            <Hoverable hoverFill="#EFEFEF" cursor="pointer" />
            <FlexBox gap={Metrics.rowsGap} paddingX={16} paddingY={6} background={{ fill: value?.color ?? "#797e93" }}>
              {cardText(value?.text ?? "Unset", { fill: "white" })}
            </FlexBox>
            {renderEditValue(column)}
          </Group>
        );
      case "date":
        const dateText = value ? format(new Date(value), "MMM dd, yyyy") : "-";
        return (
          <Group
            onClick={() => {
              tracking.trackEvent(consts.TRACKING_CATEGORY.INTEGRATIONS, "column_value_clicked", type);
              setOpenedColumnId(id);
            }}
          >
            <Hoverable hoverFill="#EFEFEF" cursor="pointer" />
            {cardText(dateText, { fill: "#848199", width: 260 })}
            {renderEditValue(column)}
          </Group>
        );
      case "people":
        return cardValueText(value ?? "-");
      case "text":
        const isEditing = isEditable && openedColumnId === id;
        return (
          <Group
            onClick={() => {
              tracking.trackEvent(consts.TRACKING_CATEGORY.INTEGRATIONS, "column_value_clicked", type);
              setOpenedColumnId(id);
            }}
          >
            <Hoverable hoverFill="#EFEFEF" cursor="pointer" />
            {cardText(value ?? "Unset", { fill: "#848199", width: 260, opacity: isEditing ? 0 : 1 })}
            {isEditing && (
              <CustomTextInput
                initialValue={value}
                fontFamily={fontFamily}
                fontSize={16}
                fill={fontColor}
                fontVariant="300"
                placeholder={"Enter text value"}
                width={260}
                onHeightChange={(h) => {}}
                onEscape={() => setOpenedColumnId(null)}
                onEnter={(newValue) => {
                  if (newValue != value) onChangeColumnValue(id, newValue);
                  setOpenedColumnId(null);
                  transformerRef.current?.forceUpdate();
                }}
              />
            )}
          </Group>
        );
      case "file":
        if (!value || !Array.isArray(value) || value.length === 0) {
          return cardText("No files", { fill: "#848199" });
        }
        return (
          <FlexBox direction="column" gap={8}>
            {value.map((file: any) => {
              const icon = file.isImage ? (
                <SomeImage
                  src={`../${getPathPrefix("/images/file_column_image_icon.svg")}`}
                  size={{ width: 16, height: 16 }}
                />
              ) : (
                <SomeImage
                  src={`../${getPathPrefix("/images/file_column_file_icon.svg")}`}
                  size={{ width: 16, height: 20 }}
                />
              );
              const canBePreview = file.isImage;
              const isPreview = previewAssetIds.includes(file.id);
              return (
                <Group key={file.id}>
                  <FlexBox align="center" gap={8}>
                    <FlexBox
                      background={{ stroke: "#DADCE0" }}
                      paddingX={14}
                      paddingY={6}
                      gap={8}
                      width={270}
                      align="center"
                    >
                      {icon}
                      {cardText(file.name, { fill: "#11355E", width: 190, ellipsis: true, height: 20 })}
                      {canBePreview && (
                        <Group onClick={() => togglePreviewAsset(file.id)}>
                          {isPreview ? (
                            <SomeImage
                              src={`../${getPathPrefix("/images/opened-eye-icon.svg")}`}
                              size={{ width: 24, height: 16 }}
                            />
                          ) : (
                            <SomeImage
                              src={`../${getPathPrefix("/images/closed-eye-icon.svg")}`}
                              size={{ width: 16, height: 8 }}
                            />
                          )}
                        </Group>
                      )}
                    </FlexBox>
                    <Group onClick={() => downloadAssetURL(file.id)}>
                      <SomeImage
                        src={`../${getPathPrefix("/images/file-column-download-icon.svg")}`}
                        size={{ width: 16, height: 16 }}
                      />
                    </Group>
                  </FlexBox>
                </Group>
              );
            })}
          </FlexBox>
        );
      default:
        const text = value ?? "-";
        return cardText(text, { fill: "#848199", width: 260 });
    }
  }

  function downloadAssetURL(fileId: string) {
    const file = element.columnValues
      .find((columnValue: any) => columnValue.type === "file")
      ?.value.find((file: any) => file.id === fileId);
    if (!file) {
      return;
    }
    window.open(file.url);
  }

  function togglePreviewAsset(fileId: string) {
    const index = previewAssetIds.indexOf(fileId);
    if (index === -1) {
      onChangeConfiguration({ ...configuration, previewAssetIds: [...previewAssetIds, fileId] });
    } else {
      onChangeConfiguration({
        ...configuration,
        previewAssetIds: previewAssetIds.filter((id: string) => id !== fileId),
      });
    }
    setTimeout(() => {
      transformerRef.current?.forceUpdate();
    }, 0);
  }

  function renderColumnValueRow(column: any) {
    let value = element.columnValues.find((columnValue: any) => columnValue.id === column.id);

    let align: "center" | "leading" = "center";
    if (column.type === "file") {
      align = "leading";
    }

    // if the column value is not set, render an empty value
    value = Object.assign({}, value, { type: column.type, id: column.id });
    return (
      <FlexBox align={align} gap={20} key={column.id}>
        {cardText(`${column.title}:`, { fontVariant: "400", width: 100 })}
        {columnValueContent(value)}
      </FlexBox>
    );
  }

  function renderUpdate(update: any) {
    const expanded = expandedUpdateIds.includes(update.id);
    const textProps = expanded ? {} : { height: 20, ellipsis: true };
    return (
      <Group key={update.id}>
        <Hoverable
          hoverFill="#EFEFEF"
          autoSize={true}
          hoverChange={(hover: boolean) => setHoveredElement(hover ? update.id : null)}
          cursor="pointer"
        />
        <FlexBox
          gap={12}
          padding={Metrics.cardPadding / 2}
          background={{ fill: hoveredElement == update.id || expanded ? "#F8FAFD" : "white" }}
        >
          <SomeImage src={update.userURL} size={{ width: 30, height: 30 }} cornerRadius={15} showLoader={false} />
          <FlexBox direction="column" gap={8}>
            <FlexBox gap={8}>
              {cardText(update.userName, { fill: "#11355E", fontVariant: "400" })}
              {cardText(formatDistance(new Date(update.createdAt), new Date(), { addSuffix: true }), {
                fill: "#848199",
              })}
            </FlexBox>
            {cardText(update.text, { width: 298, ...textProps, fill: "#848199" })}
          </FlexBox>
          {(hoveredElement == update.id || expanded) &&
            renderCollapseButton(!expanded, () => {
              tracking.trackEvent(
                consts.TRACKING_CATEGORY.INTEGRATIONS,
                expanded ? "task_collapse_update_clicked" : "task_expand_update_clicked"
              );
              expandUpdateChanged(update.id, !expanded);
            })}
        </FlexBox>
      </Group>
    );
  }

  function renderUpdates() {
    if (!element || !element.updates || element.updates.length === 0) {
      return null;
    }

    const updates = showAllUpdates ? element.updates : element.updates.slice(0, 1);
    const showMoreButton = element.updates.length > 1;

    return (
      <FlexBox direction="column" padding={Metrics.cardPadding / 2}>
        <FlexBox paddingX={Metrics.cardPadding / 2} paddingY={Metrics.cardPadding / 4}>
          {cardText("Updates:", { fontVariant: "400" })}
        </FlexBox>
        {updates.map(renderUpdate)}
        {showMoreButton && (
          <FlexBox align="center" gap={12} padding={Metrics.cardPadding / 2}>
            <Group onClick={toggleShowAllUpdates}>
              <Hoverable hoverFill="#F8FAFD" strokeWidth={1} stroke="#EBEDF3" cursor="pointer" />
              <FlexBox align="center" padding={Metrics.cardPadding / 2}>
                {cardText(showAllUpdates ? "Show Less" : `Show ${element.updates.length - 1} more`, {
                  fill: "#848199",
                })}
              </FlexBox>
            </Group>
          </FlexBox>
        )}
      </FlexBox>
    );
  }

  function renderExpandedColumnValues() {
    if (!element) {
      return null;
    }

    return (
      <FlexBox paddingY={12}>
        <FlexBox
          direction="column"
          gap={Metrics.rowsGap}
          paddingX={Metrics.cardPadding}
          paddingY={Metrics.cardPadding - 12}
          width={Metrics.cardWidth - Metrics.cardPadding - 16}
        >
          {newSelectedColumns.map(renderColumnValueRow)}
        </FlexBox>
        {isEditable && (
          <Group
            onClick={() => {
              tracking.trackEvent(consts.TRACKING_CATEGORY.INTEGRATIONS, "task_edit_configuration_clicked");
              setShowConfiguration((show) => !show);
            }}
          >
            <Hoverable hoverFill="#EFEFEF" cornerRadius={4} autoSize={true} cursor="pointer" />
            <FlexBox padding={8}>
              <SomeImage src={`../${getPathPrefix("/images/pencil-icon.svg")}`} size={{ width: 16, height: 16 }} />
              {showConfiguration && (
                <Html groupProps={{ x: 30, y: -10 }}>
                  <IntegrationConfiguration
                    documentId={documentId}
                    integrationId={integrationId}
                    // BUG here: when clicking on the pencil icon when this is open
                    // the configuration is not closed. there's onOutsideClick handler that closes it,
                    // and then the pencil toggles it to open again
                    onDismiss={() => setShowConfiguration(false)}
                  />
                </Html>
              )}
            </FlexBox>
          </Group>
        )}
      </FlexBox>
    );
  }

  function renderPreviewAssets() {
    if (previewAssetIds.length === 0) {
      return null;
    }

    return (
      <>
        <MondayTaskPreviewAssetsCarousel assets={previewAssets} size={Metrics.cardWidth} />
        {renderSeparator()}
      </>
    );
  }

  function renderCollapseButton(collapsed: boolean, onCollapseChange: (collapsed: boolean) => void) {
    return !isExporting && <IntegrationToggleButton on={!collapsed} onClick={() => onCollapseChange(!collapsed)} />;
  }

  function renderSeparator() {
    return <Rect width={Metrics.cardWidth} height={1} fill={"#DADCE0"} />;
  }

  function renderContent() {
    if (!expanded) {
      return renderCollapsedColumnValues();
    }

    return (
      <>
        {renderExpandedColumnValues()}
        {renderSeparator()}
        {renderUpdates()}
      </>
    );
  }

  const backgroundProps = {
    fill: "white",
    shadowColor: "rgba(0, 0, 0, 0.12)",
    shadowBlur: 12,
    shadowOffsetY: 4,
    stroke: "#EBEDF3",
    strokeWidth: 2,
    shadowForStrokeEnabled: false,
  };

  function cardTitle() {
    const title = element?.name;
    const hasTitle = !!title;
    const textAttributes: TextConfig = {
      width: Metrics.titleWidth,
      fontSize: 18,
      fill: "#11355E",
      fontVariant: "300",
      lineHeight,
      fontFamily,
      ellipsis: true,
    };
    if (title && !expanded) {
      const template = new Konva.Text({ text: title, ...textAttributes });
      const height = Math.min(template.height(), 60);
      textAttributes.height = height;
    }

    if (!isEditing) {
      return (
        <Group key="static-title" onClick={hasTitle && isEditable ? dblClickToEditTitle : undefined}>
          {title && <Hoverable hoverFill="#F8FAFD" cornerRadius={2} autoSize={true} />}
          <FlexBox paddingX={Metrics.titlePaddingX} paddingY={Metrics.titlePaddingY}>
            {cardText(title ?? "Loading...", textAttributes)}
          </FlexBox>
        </Group>
      );
    } else {
      return (
        <Group key="editable-title">
          <Rect
            fill="transparent"
            width={Metrics.titleWidth + Metrics.titlePaddingX * 2}
            height={textHeight + Metrics.titlePaddingY * 2}
          />
          <CustomTextInput
            initialValue={title}
            fontFamily={fontFamily}
            fontSize={18}
            fill={fontColor}
            fontVariant="300"
            placeholder={"Card title..."}
            width={Metrics.titleWidth}
            onHeightChange={(h) => {
              setTextHeight(h);
              setTimeout(() => transformerRef.current?.forceUpdate());
            }}
            onEscape={() => setIsEditing(false)}
            onEnter={(value) => {
              setIsEditing(false);
              if (value.length > 0 && value != title) onChangeColumnValue("name", value);
            }}
            customStyle={{ margin: `${Metrics.titlePaddingY}px ${Metrics.titlePaddingX}px` }}
          />
        </Group>
      );
    }
  }

  function generateTrimmedBreadcrumbs(parts: string[], maxLength: number = 60) {
    let breadcrumbs = parts.join(" > ");
    let trimLettersCount = 0;

    // Trim the longest part of the breadcrumbs until it fits within the maxLength
    // we adjust the trim count based on the number of parts to keep the breadcrumbs as balanced as possible
    while (breadcrumbs.length > maxLength) {
      trimLettersCount = (breadcrumbs.length - maxLength) / parts.length;
      const longestPart = parts.reduce((a, b) => (a.length > b.length ? a : b));
      const index = parts.indexOf(longestPart);
      parts[index] = longestPart.slice(0, longestPart.length - trimLettersCount - 3) + "...";
      breadcrumbs = parts.join(" > ");
    }

    return breadcrumbs;
  }

  function cardBreadcrumbs() {
    let breadcrumbs: string;
    if (element.parent_item) {
      if (!element.parent_item.board?.name || !element.parent_item.name) {
        return null;
      }
      breadcrumbs = generateTrimmedBreadcrumbs([element.parent_item.board.name, element.parent_item.name, "Subitems"]);
    } else {
      if (!element.boardName || !element.group?.title) {
        return null;
      }
      breadcrumbs = generateTrimmedBreadcrumbs([element.boardName, element.group.title]);
    }
    return (
      <ClickDragDetectorGroup
        onClick={() => {
          window.open(element.url);
        }}
      >
        <Hoverable cursor="pointer" />
        <FlexBox paddingX={Metrics.cardPadding} gap={8} direction="column">
          <Rect height={Metrics.cardPadding} />
          {cardValueText(breadcrumbs, { fontSize: 14 })}
        </FlexBox>
      </ClickDragDetectorGroup>
    );
  }

  return (
    <FlexBox direction="column" background={{ ...backgroundProps, stroke: strokeColor }}>
      <Rect width={Metrics.cardWidth} height={12} fill={strokeColor} />
      {renderPreviewAssets()}
      {cardBreadcrumbs()}
      <FlexBox padding={Metrics.cardPadding} gap={16}>
        {cardTitle()}
        {renderCollapseButton(!expanded, (collapsed) => {
          tracking.trackEvent(
            consts.TRACKING_CATEGORY.INTEGRATIONS,
            collapsed ? "task_collapse_clicked" : "task_expand_clicked"
          );
          expandChanged(!collapsed);
        })}
      </FlexBox>
      {renderSeparator()}
      {renderContent()}
    </FlexBox>
  );
}

function EditWrapper({ children }: { children: any }) {
  return <Html groupProps={{ x: -12, y: 40 }}>{children}</Html>;
}

function CustomTextInput({
  initialValue,
  fontFamily,
  fontSize,
  fill,
  fontVariant,
  placeholder,
  width,
  onHeightChange,
  onEnter,
  onEscape,
  customStyle,
}: {
  initialValue: string;
  fontFamily: string;
  fontSize: number;
  fill: string;
  fontVariant: string;
  placeholder: string;
  width: number;
  onHeightChange: (height: number) => void;
  onEnter?: (value: string) => void;
  onEscape?: (value: string) => void;
  customStyle?: CSSProperties;
}) {
  const [height, setHeight] = useState(0);
  const [text, setText] = useState(initialValue);
  const ref = useRef<HTMLTextAreaElement>(null);

  const updateOnTextChange = () => onEnter && onEnter(text);
  useOnClickOutside(ref, updateOnTextChange);

  function recalcHeight(node: HTMLTextAreaElement): number {
    node.style.height = "0";
    node.style.fontSize = fontSize + "px";
    let newHeight = node.scrollHeight;
    node.style.height = `${newHeight}px`;
    return newHeight;
  }

  useEffect(() => {
    if (ref.current) {
      const node = ref.current;
      let newHeight = recalcHeight(node);
      setHeight(newHeight);
      let h = newHeight;
      onHeightChange && onHeightChange(h);
    }
  }, [fontSize, fontFamily, width]);

  const elRef = useCallback((node: HTMLTextAreaElement) => {
    if (ref.current) {
      // cleanup previous callbacks
    }
    // initialize the new element here
    if (node != null) {
      node.select();
      node.focus();
      recalcHeight(node);
    }
    //save reference for later (if needed)
    (ref as React.MutableRefObject<HTMLTextAreaElement>).current = node;
  }, []);

  return (
    <Html>
      <textarea
        ref={elRef}
        value={text}
        placeholder={placeholder}
        onKeyDown={(e) => {
          handleTabInTextArea(e);
          if (onEnter && e.key == "Enter" && !e.shiftKey) {
            e.preventDefault();
            updateOnTextChange();
          }
          if (onEscape && e.key == "Escape") {
            e.preventDefault();
            onEscape(text);
          }
        }}
        onChange={(e) => {
          const node = e.target;
          const text = e.target.value;
          let newHeight = recalcHeight(node);
          setHeight(newHeight);
          setText(text);
          let h = newHeight;
          onHeightChange && onHeightChange(h);
        }}
        style={{
          width,
          height,
          resize: "none",
          outline: "none",
          border: "none",
          padding: 0,
          fontFamily,
          fontSize,
          color: fill,
          fontWeight: "unset",
          fontVariant,
          lineHeight: "1.3",
          overflow: "hidden",
          ...customStyle,
        }}
      />
    </Html>
  );
}
